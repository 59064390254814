import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment'

export interface MediegoMessage {
  id: string;
  content: string;
  tag: string;
  publicationDate: string;
  validity: number;
  targetedEngines: number[];

  imgSrc?: string;
  pending?: boolean;
}

import { firstValueFrom } from 'rxjs';

import { UserPermissions } from '../main-module/declarations/engine';
@Injectable({
  providedIn: 'root'
})
export class MiscProvider {

  private readonly DEFAULT_TAB_TITLE: string;

  constructor(private http: HttpClient) {
    this.DEFAULT_TAB_TITLE = document.title;
  }

  addBellToTabTitle() {
    document.title = '🔔 ' + this.DEFAULT_TAB_TITLE;
  }

  restoreDefaultTabTitle() {
    document.title = this.DEFAULT_TAB_TITLE;
  }

  getLatestMediegoMessages(): Promise<MediegoMessage[]> {
    const messages$ = this.http.get<MediegoMessage[]>(`${environment.apiUrl2}/2.0/dashboard/messages`, { responseType: 'json' });
    return firstValueFrom(messages$);
  }

  deleteMediegoMessage(message: MediegoMessage): Promise<void> {
    // Suppression logique, ce n'est pas une véritable suppression
    message.publicationDate = '2001-01-01T00:00:00.000Z';
    message.validity = 0;
    const messages$ = this.http.put<void>(
      `${environment.apiUrl2}/2.0/dashboard/messages/${message.id}`,
      message
    );
    return firstValueFrom(messages$);
  }

  putMediegoMessage(message: MediegoMessage): Promise<void> {
    const messages$ = this.http.put<void>(
      `${environment.apiUrl2}/2.0/dashboard/messages/${message.id}`,
      message
    );
    return firstValueFrom(messages$);
  }

  fromPermissionsV1toV2(oldPermissions: { [engineId: string]: UserPermissions }): Promise<any> {
    const permissions$ = this.http.post(
      `${environment.apiUrl2}/2.0/dashboard/permissions/to-api`,
      Object.values(oldPermissions),
      {
        responseType: 'json'
      });
    return firstValueFrom(permissions$);
  }


}
